import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  version = `2.5.0 ${environment.production ? 'PROD' : 'DEV'}`;

}
