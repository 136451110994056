import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common/common.service';
import { DataPocketService } from 'src/app/services/data-pocket/data-pocket.service';
import { AppRoute } from 'src/app/shared/constants/app-route.constant';
import { GlobalConfigKey, StorageKey } from 'src/app/shared/constants/global-config.constant';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent {

  constructor(
    private router: Router,
    private ngbModal: NgbModal,
    private ngbModalConfig: NgbModalConfig,
    public commonService: CommonService,
    private dataPocketService: DataPocketService,

  ) {

    ngbModalConfig.backdrop = 'static';
    ngbModalConfig.keyboard = false;

  }

  modalRef: NgbModalRef;
  @ViewChild('termTab') content;

  async ngAfterViewInit() {

    try {
      this.checkSkipTerms();
    }
    catch (err) {

    }
    finally {

    }

  }

  openModal() {
    this.modalRef = this.ngbModal.open(this.content, { scrollable: true, centered: true, size: 'xl', windowClass: 'width-80' });
  }

  onDecline() {
    this.modalRef.close();
    this.router.navigate([AppRoute.HOME]);
  }

  async onAccept() {

    this.commonService.isLoading = true;
    try {
      if(this.modalRef) {
        this.modalRef.close();
      }
      await this.checkFreeWhiteList();
      // this.router.navigate([AppRoute.PAYMENT_OR_PROMO]);
    }
    catch (err) {
      console.log(err);
    }
    finally {
      this.commonService.isLoading = false;
    }

  }

  async checkFreeWhiteList() {
    await this.dataPocketService.loginPocketbase();
    const configResult = await this.dataPocketService.getGlobalConfig(GlobalConfigKey.FREE_WHITELIST_BRANCH).toPromise();
    if (configResult) {
      if (configResult.items.length > 0) {
        if (configResult.items[0].value.includes(localStorage.getItem(StorageKey.BRANCH_CODE))) {
          this.router.navigate([AppRoute.PAYMENT_SUCCESS]);
        }
        else {
          this.router.navigate([AppRoute.PAYMENT_OR_PROMO]);
        }
      }
      console.log(configResult);
    }
  }

  async checkSkipTerms() {
    await this.dataPocketService.loginPocketbase();
    const configResult = await this.dataPocketService.getGlobalConfig(GlobalConfigKey.SCULPTURE_BRANCH_SKIP_TERMS).toPromise();
    if (configResult) {
      if (configResult.items.length > 0) {
        if (configResult.items[0].value.includes(localStorage.getItem(StorageKey.BRANCH_CODE))) {
          console.log('auto accept');
          await this.onAccept();
        }
        else {
          this.openModal();
        }
      }
      console.log(configResult);
    }
  }

}
