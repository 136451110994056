<div class="content-wrapper">

</div>

<ng-template #termTab let-modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title robotomono-font"><strong>Terms and Condition / Privacy Policy</strong></h4>
    </div>
    <div class="modal-body" style="background-color: #ffffff;">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li [ngbNavItem]="1">
                <ng-template ngbNavContent>
                    <h3 class="text-center"><strong>ข้อตกลงในการใช้งาน</strong></h3>
                    <p><strong>โครงการ Sculpture Bangkok
                            ขอแจ้งนโยบายการคืนเงินเพื่อเป็นข้อตกลงและสร้างความเข้าใจเกี่ยวกับการใช้บริการเว็บไซต์
                            ดังนี้</strong></p>
                    <ul>
                        <li>Sculpture Bangkok จะทำการคืนเงินค่าสินค้าให้กับลูกค้า ในกรณีที่ Sculpture Bangkok
                            ไม่สามารถบริการสินค้าตามที่ลูกค้าสั่งซื้อได้</li>
                    </ul>
                    <p><strong>ระยะเวลาการคืนเงินค่าสินค้า มีรายละเอียดดังนี้</strong></p>
                    <ul>
                        <li>กรณีลูกค้าชำระเงินเต็มจำนวน โอนเงินเข้าบัญชี Sculpture Bangkok
                            จะทำการคืนเงินกลับไปยังบัญชีที่ลูกค้าใช้ในการชำระเงิน โดยใช้ระยะเวลาประมาณ 15-30 วันทำการ
                        </li>
                        <li>กรณีลูกค้าชำระเงินเต็มจำนวน ผ่านช่องทาง Internet Banking
                            จะทำการคืนเงินกลับไปยังเลขที่บัญชีที่ลูกค้าแจ้ง โดยใช้ระยะเวลาประมาณ 15-30 วันทำการ</li>
                    </ul>
                    <p><strong>ไม่สามารถดำเนินการยกเลิก เปลี่ยนแปลง แก้ไข รายการสั่งซื้อสินค้า ในกรณีต่างๆ ดังนี้</strong></p>
                    <ul>
                        <li>กรณีที่เกิดขึ้นจากความผิดพลาดในการสั่งซื้อสินค้า ผิดสี, ผิดรุ่น, ผิดประเภทฯ</li>
                        <li>กรณีที่เกิดขึ้นจากการเปลี่ยนแปลงของราคาสินค้าที่อาจเกิดขึ้นได้ในอนาคต</li>
                        <li>กรณีที่เกิดขึ้นจากการเปลี่ยนใจ (Change of mind) ของผู้สั่งซื้อสินค้าทุกกรณี</li>
                    </ul>
                    <p><strong>Sculpture Bangkok จะไม่ดำเนินบริการ และต้องทำการคืนเงินเท่านั้น เนื่องจากกรณีต่างๆ ดังนี้</strong>
                    </p>
                    <ul>
                        <li>กรณีที่เกิดขึ้นจากสินค้าหมด (เช่น ฟิล์มรูปหมด, สินค้ายกเลิก)</li>
                        <li>กรณีที่เกิดขึ้นจากราคาสินค้าที่ระบุทางหน้า Website ไม่ตรงกับราคาที่จำหน่ายจริง</li>
                        <li>กรณีที่เกิดขึ้นจากเหตุสุดวิสัย (System Error) ที่อาจเกิดขึ้น</li>
                        <li>Sculpture Bangkok ขอสงวนสิทธิ์ในการคืนเงินเกินระยะเวลาที่กำหนด หากเกิดเหตุสุดวิสัย</li>
                        <li>Sculpture Bangkok จะคืนเงินในกรณีที่ทางโครงการมองว่าเป็นความผิดของโครงการจริง
                            และต้องระบุเป็นลายลักษณ์อักษร และภาพถ่ายหลักฐานชัดเจน พร้อมส่งมาที่
                            thisissculpture&#64;gmail.com</li>
                        <li>Sculpture Bangkok ขอสงวนสิทธิในการเปลี่ยนแปลงเงื่อนไขใดๆ โดยไม่จำต้องแจ้งให้ทราบล่วงหน้า
                        </li>
                    </ul>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
    <div class="modal-footer text-left">
        <button type="button" class="btn btn-light robotomono-font mr-auto" (click)="onDecline()"><strong>Not Accept</strong></button>
        <button type="button" class="btn btn-dark robotomono-font" (click)="onAccept()"><strong>Accept</strong></button>
    </div>
</ng-template>
<app-loading *ngIf="commonService.isLoading"></app-loading>